<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="green" v-bind="attrs" v-on="on">
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </template>
          <span>Replace end url Domain</span>
        </v-tooltip>
      </div>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Replace end url Domain </span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-container>
          <v-form ref="replaceEndUrlForm" v-model="valid" class="w-100">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Old end url *"
                  required
                  v-model="old_domain"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="New end url *"
                  required
                  v-model="new_domain"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Offer keys"
                  v-model="offer_keys"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" class="offers-select">
              <v-autocomplete
                ref="offersAutocomplete"
                :search-input.sync="searchOffers"
                @focus="() => onGetOffers()"
                placeholder="Search"
                v-model="select_offers"
                :items="offersList"
                chips
                deletable-chips
                label="Offers"
                multiple
                return-object
                item-text="name"
                item-value="id"
                clearable
                no-filter
                :menu-props="{ maxHeight: 200, bottom: true }"
              ></v-autocomplete>
            </v-col> -->
            </v-row>
          </v-form>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModal"> Close </v-btn>
        <v-btn
          color="blue darken-1"
          text
          type="button"
          @click="onReplaceDomains"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requestStatus } from "@/services/services";
import { endUrlsReplace } from "@/store/modules/offers/api";
// import { loadList } from "@/store/modules/offers/api";
export default {
  props: ["updadeList"],
  data() {
    return {
      select_offers: [],
      searchOffers: "",
      dialog: false,
      old_domain: "",
      new_domain: "",
      offer_keys: "",
      offers: [],
      valid: false,
      rules: {
        required: (value) => !!value || "This field is required",
      },
    };
  },
  // computed: {
  //   offersList() {
  //     return [...this.select_offers, ...this.offers];
  //   },
  // },
  watch: {
    // select_offers() {
    //   // this.searchOffers = "";
    // },
    // searchOffers(newValue) {
    //   if (
    //     (newValue || newValue === "") &&
    //     (newValue.length >= 2 || newValue === "")
    //   ) {
    //     this.onGetOffers(newValue);
    //   }
    // },
  },
  methods: {
    // onGetOffers(search) {
    //   loadList(`ordering=-created_at&search=${search ? search : ""}`).then(
    //     (response) => {
    //       if (response.status === requestStatus.success) {
    //         this.offers = response.response.data.results;
    //       } else {
    //         this.$toaster.error(
    //           response.messages || "Oops, something went wrong."
    //         );
    //       }
    //     }
    //   );
    // },
    onReplaceDomains() {
      this.$refs.replaceEndUrlForm.validate();
      if (this.valid) {
        endUrlsReplace({
          old_domain: this.old_domain,
          new_domain: this.new_domain,
          offer_keys: this.offer_keys,
        }).then((response) => {
          if (response.status === requestStatus.success) {
            this.$toaster.success("Domains end urls have been changed");
            this.updadeList();
            this.closeModal();
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        });
      }
    },

    closeModal() {
      this.offers = [];
      this.select_offers = [];
      this.old_domain = "";
      this.new_domain = "";
      this.offer_keys = "";
      this.$refs.replaceEndUrlForm.resetValidation();
      this.dialog = false;
    },
  },
};
</script>
