<template>
  <div class="pa-2">
    <v-card-text class="pt-4">
      <v-row>
        <v-form ref="createCountyCode" class="w-100">
          <v-col cols="12">
            <v-text-field
              :rules="[rules.required]"
              required
              v-model="county_name"
              label="Country name"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :rules="[rules.required]"
              required
              v-model="county_code"
              label="Country code"
            ></v-text-field>
          </v-col>
        </v-form>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :loading="loading" color="blue darken-1" text @click="closeForm">
        Close
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        type="button"
        @click="onSave"
        :loading="loading"
      >
        Save
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { requestStatus } from "@/services/services";
import { createCountries } from "@/store/modules/offers/api";
import _ from "lodash";
export default {
  props: ["closeDialog"],
  data() {
    return {
      county_name: "",
      county_code: "",
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required",
      },
    };
  },
  methods: {
    onSave() {
      if (this.$refs.createCountyCode.validate()) {
        this.loading = true;
        let data = {
          name: this.county_name,
          code: this.county_code,
        };
        createCountries(data)
          .then((response) => {
            if (response.status === requestStatus.success) {
              this.$toaster.success("Country created");
              this.clearForm();
            } else {
              this.$toaster.error(
                response.messages || "Oops, something went wrong."
              );
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    clearForm() {
      this.county_name = "";
      this.county_code = "";
      this.$refs.createCountyCode.resetValidation();
    },
    closeForm() {
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss">
.change-server-wrp {
  .v-select.v-select--chips .v-select__selections {
    min-height: 32px !important;
  }
}
</style>
