<template>
  <v-container :fluid="true">
    <offers-table  />
  </v-container>
</template>

<script>
import OffersTable from "./components/Table";
import {mapActions, mapMutations} from "vuex";
import {SET_DEFAULT_STATE} from "../../store/modules/offers/types";
import {ADD_HEADER_SETTINGS} from "../../store/modules/layout/types";

export default {
  name: "Offers",
  components: {OffersTable},
  data: () => ({
    isShowCreateDomain: false
  }),
  methods: {
    ...mapActions({
      addSettings: `layout/${ADD_HEADER_SETTINGS}`,
    }),
    ...mapMutations({
      onClearData: `offers/${SET_DEFAULT_STATE}`,
    }),
  },
  beforeMount() {
    this.addSettings({
      pageName: 'Offers',
      actions: []
    })
  },
  beforeRouteLeave(to, from, next) {
    this.onClearData();
    next();
  }
}
</script>

<style scoped>

</style>