<template>
  <v-card>
    <v-card-title>
      <div
        class="d-flex justify-content-between w-100 align-center domain-filters"
      >
        <v-row>
          <!-- <v-col cols="12" sm="4">
            <v-select
              :items="isActiveFilter"
              label="Is Active"
              :value="is_active"
              item-text="label"
              item-value="value"
              @change="(is_active) => changeFilter({ is_active })"
            ></v-select>
          </v-col> -->
          <v-col cols="12" sm="2">
            <v-text-field
              label="Search..."
              v-model="searchOffer"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="country"
              :items="countries_list"
              :loading="isLoadingCountries"
              :search-input.sync="searchCountries"
              @focus="() => onLoadCountries()"
              placeholder="Search"
              item-text="name"
              item-value="code"
              label="Country"
              required
              @change="changeCountry(country)"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-if="networks && networks.length"
              v-model="network"
              :items="networks"
              item-text="name"
              item-value="id"
              label="Network"
              required
              @change="changeNetwork(network)"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              :items="isDraftFilter"
              label="Is Draft"
              :value="is_draft"
              item-text="label"
              item-value="value"
              @change="(is_draft) => changeFilter({ is_draft })"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              :items="isHelthyFilter"
              label="Is working"
              :value="is_healthy ? is_healthy : null"
              item-text="label"
              item-value="value"
              @change="(is_healthy) => changeFilter({ is_healthy })"
            ></v-select>
          </v-col>
        </v-row>
        <div class="d-flex align-center">
          <v-btn color="primary" class="ma-2" to="offers/create">
            Create Offer
          </v-btn>
          <ReplaceEndUrlDomain :updadeList="() => onLoadData()" />
          <SettingsDialog />
        </div>
      </div>
    </v-card-title>
    <v-card-text :key="keyTable">
      <v-data-table
        :headers="headers"
        :items="list"
        hide-default-footer
        :loading="loading"
        :itemsPerPage="+pageFilter.limit"
        :item-class="rowClass"
        disable-sort
        dense
      >
        <template v-slot:header.name="{ header }">
          {{ header.text.toUpperCase() }}
        </template>

        <template
          v-for="header in headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ header, value }"
        >
          {{ header.formatter(value) }}
        </template>

        <!-- <template v-slot:item.test="{ item }">
          <td :rowspan="1">
            <div style="display: flex; align-items: center">
              {{ item.name }}
            </div>
          </td>
        </template> -->

        <template v-slot:item.name="{ item }">
          <div style="display: flex; align-items: center">
            <v-menu
              bottom
              :close-on-content-click="true"
              content-class="drop-menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="info" v-bind="attrs" v-on="on" small
                        >mdi-dots-vertical
                      </v-icon>
                    </template>
                    <span>Actions</span>
                  </v-tooltip>
                </div>
              </template>

              <v-list>
                <v-list-item>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        icon
                        x-small
                        title="Download"
                        v-bind="attrs"
                        v-on="on"
                        target="_blank"
                        @click="downloadLink(item.id, item)"
                      >
                        <v-icon small>mdi-download-circle-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                </v-list-item>
                <v-list-item>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        icon
                        x-small
                        title="Copy"
                        @click="createOfferCopy(item.id)"
                        v-bind="attrs"
                        v-on="on"
                        :loading="copyOfferLoader"
                      >
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Create offer copy</span>
                  </v-tooltip>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              icon
              x-small
              color="primary"
              title="Copy offer url"
              @click="onClickCopy(item.offer_url)"
            >
              <v-icon small>mdi-link-variant </v-icon>
            </v-btn>
            <v-btn
              v-if="item.offer_url"
              color="primary"
              icon
              x-small
              target="_blank"
              title="Preview"
              :href="`${item.offer_url}?holydebug=1`"
            >
              <v-icon small>mdi-open-in-new </v-icon>
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 4px;
                  "
                >
                  <country-flag
                    v-if="item.country"
                    :country="item.country"
                    size="small"
                    :title="item.country"
                  />
                </div>
              </template>
              <span>{{ item.country }}</span>
            </v-tooltip>

            <v-btn text small :to="`offers/${item.id}`">
              {{
                item.name.length > 35
                  ? `${item.name.slice(0, 35)}...`
                  : item.name
              }}
            </v-btn>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  color="success"
                  class="mr-1"
                >
                  <b>{{ item.network.name }}</b>
                </v-chip>
              </template>
              <span>Network - {{ item.network.name }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.target="{ item }">
          {{ item.target_offer.name }}
        </template>

        <template v-slot:item.is_draft="{ item }">
          <div
            class="d-inline-flex"
            :key="`${item.id}${status.editing_offer === item.id}`"
          >
            <v-switch
              hide-details
              class="ma-0 pa-0"
              :input-value="item.is_draft"
              :loading="
                isEditing && status.editing_offer === item.id
                  ? 'warning'
                  : false
              "
              @click="(e) => toggleSwitcher(e, item)"
              :disabled="isEditing && status.editing_offer === item.id"
            >
            </v-switch>
          </div>
        </template>

        <template v-slot:footer>
          <pagination :count="count" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CountryFlag from "vue-country-flag";

import { mapActions, mapMutations, mapState } from "vuex";
import { LOAD_LIST, EDIT_DRAFT } from "../../../store/modules/offers/types";
import {
  renderDateTime,
  requestStatus,
  buildFilterString,
  onCopyToClipboard,
  toBooleanValue,
} from "@/services/services";
import Pagination from "@/components/Pagination";
import { CHANGE_FILTER } from "../../../store/modules/page/types";
import {
  createOfferCopy,
  downloadOffers,
} from "../../../store/modules/offers/api";
import { LOAD_COUNTRIES, LOAD_NETWORKS } from "@/store/modules/offers/types";
import ReplaceEndUrlDomain from "./ReplaceEndUrlDomain.vue";
import SettingsDialog from "../settings/SettingsDialog.vue";
export default {
  name: "OffersTable",
  components: { Pagination, CountryFlag, ReplaceEndUrlDomain, SettingsDialog },
  data: () => ({
    network: "",
    copyOfferLoader: false,
    downloadBtnLoader: false,
    searchOffer: "",
    country: null,
    isLoadingCountries: false,
    headers: [
      // {
      //   text: "Test",
      //   align: "start",
      //   value: "test",
      // },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Total visits",
        value: "total_visits",
      },
      {
        text: "Сlicks",
        value: "total_submits",
      },
      {
        text: "CTR",
        value: "ctr",
        formatter: (x) => (x ? `${+x.toFixed(2)}%` : null),
      },
      {
        text: "Target offer",
        value: "target",
      },
      {
        text: "Key",
        value: "short_key",
      },
      {
        text: "Domain",
        value: "domain",
        formatter: (x) => (x ? x.domain : null),
      },
      {
        text: "Created at",
        value: "created_at",
        formatter: (x) => (x ? renderDateTime(x) : null),
      },
      {
        text: "Last visit at",
        value: "last_visit_at",
        formatter: (x) => (x ? renderDateTime(x) : null),
      },
      { text: "Is Draft", value: "is_draft" },
    ],
    searchCountries: "",
    loading: false,
    isActiveFilter: [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: true,
      },
      {
        label: "Inactive",
        value: false,
      },
    ],
    isDraftFilter: [
      {
        label: "All",
        value: null,
      },
      {
        label: "Draft",
        value: true,
      },
      {
        label: "Published",
        value: false,
      },
    ],
    isHelthyFilter: [
      {
        label: "All",
        value: null,
      },
      {
        label: "Working",
        value: "true",
      },
      {
        label: "Not working",
        value: "false",
      },
    ],
    isEditing: false,
    keyTable: "",
  }),
  computed: {
    ...mapState({
      errors: (state) => state.offers.errors,
      list: (state) => state.offers.list,
      count: (state) => +state.offers.count,
      status: (state) => state.offers.status,
      pageFilter: (state) => state.page.filter,
      is_active: (state) => state.page.filter.is_active,
      is_draft: (state) => toBooleanValue(state.page.filter.is_draft),
      searchParams: (state) => state.page.filter.search,
      networkParams: (state) => state.page.filter.network,
      countries_list: (state) => state.offers.countries,
      networks: (state) => state.offers.networks,
      is_healthy: (state) => state.page.filter.is_healthy,
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    onCopyToClipboard: () => onCopyToClipboard,
  },
  methods: {
    ...mapActions({
      onLoadList: `offers/${LOAD_LIST}`,
      onEditOffer: `offers/${EDIT_DRAFT}`,
      onLoadCountries: `offers/${LOAD_COUNTRIES}`,
      onLoadNetworks: `offers/${LOAD_NETWORKS}`,
    }),
    ...mapMutations({
      changeFilter: `page/${CHANGE_FILTER}`,
    }),
    rowClass(item) {
      return item.is_healthy ? "" : "non_healthy";
    },
    changeCountry(country) {
      this.onLoadList(
        `${buildFilterString(this.pageFilter)}&ordering=-created_at${
          country ? `&country=${country}` : ""
        }`
      );
    },
    changeNetwork(network) {
      this.changeFilter({ network: network });
    },
    onWaitChangeCountries() {
      if (
        this.searchCountries &&
        (this.searchCountries.length >= 2 || this.searchCountries === "")
      ) {
        this.onLoadCountries(`search=${this.searchCountries}`);
      }
    },
    onWaitChangeOffer() {
      if (this.searchOffer.length >= 2 || this.searchOffer == "") {
        this.changeFilter({ search: this.searchOffer });
      }
    },
    onLoadData(pageFilter) {
      this.onLoadList(`${buildFilterString(pageFilter)}&ordering=-created_at`);
    },
    onClickCopy(value) {
      if (this.onCopyToClipboard(value)) {
        this.$toaster.success("Success Copy");
      }
    },
    toggleSwitcher(e, item) {
      e.preventDefault();

      this.onEditOffer({ id: item.id, data: { is_draft: !item.is_draft } });
    },
    createOfferCopy(id) {
      this.copyOfferLoader = true;
      createOfferCopy(id)
        .then((response) => {
          if (response.status === requestStatus.success) {
            this.$toaster.success("Success Offer Copy");
            this.onLoadData(this.pageFilter);
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        })
        .finally(() => {
          this.copyOfferLoader = false;
        });
    },
    downloadLink(id, item) {
      downloadOffers(id).then((response) => {
        // let filename =
        //   response.response.headers["content-disposition"].match(
        //     /filename="?([^"]+)"?/
        //   )[1];

        let url = window.URL.createObjectURL(
          new Blob([response.response.data], {
            type: response.response.headers["content-type"],
          })
        );
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${item.short_key}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
  },
  watch: {
    searchCountries() {
      this.onWaitChangeCountries();
    },
    status(newValue) {
      this.loading = newValue.load === requestStatus.loading;
      this.isEditing = newValue.edit_draft === requestStatus.loading;

      if (
        newValue.change_field === "create" &&
        newValue.create === requestStatus.success
      ) {
        this.onLoadData(this.pageFilter);
        this.$toaster.success("Success create offer");
      }

      if (
        newValue.change_field === "edit_draft" &&
        newValue.edit_draft === requestStatus.success
      ) {
        this.onLoadData(this.pageFilter);
        this.$toaster.success("Success edit offer");
      }
    },
    pageFilter(newValue) {
      this.onLoadData(newValue);
    },
    searchOffer() {
      this.onWaitChangeOffer();
    },
  },
  created() {
    this.onLoadNetworks();
    if (this.searchParams) this.searchOffer = this.searchParams;
    if (this.networkParams) this.network = +this.networkParams;
    this.onLoadData(this.pageFilter);
  },
};
</script>

<style lang="scss">
.drop-menu {
  min-width: 32px !important;
  .v-list-item {
    min-height: 20px;
    padding: 4px;
  }
}

.non_healthy {
  background: #ffe4e4;
}

@media (max-width: 768px) {
  .domain-filters {
    display: flex;
    flex-direction: column;
  }
}
</style>
