<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="info" v-bind="attrs" v-on="on">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <span>Settings</span>
        </v-tooltip>
      </div>
    </template>

    <v-card>
      <div>
        <v-tabs v-model="tab" background-color="transparent" color="info" grow>
          <v-tab class="pt-3 pb-3"> Create Country </v-tab>
          <v-tab class="pt-3 pb-3"> Create Target Offer </v-tab>
        </v-tabs>
      </div>
      <v-divider></v-divider>

      <v-tabs-items v-if="dialog" v-model="tab">
        <v-tab-item>
          <CreateCountry :closeDialog="() => closeDialog()" />
        </v-tab-item>
        <v-tab-item>
          <CreateTargetOffers :closeDialog="() => closeDialog()" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import CreateCountry from "./components/CreateCountry.vue";
import CreateTargetOffers from "./components/CreateTargetOffers.vue";
import _ from "lodash";
export default {
  components: { CreateCountry, CreateTargetOffers },
  data() {
    return {
      tab: 0,
      dialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.tab = 0;
      this.dialog = false;
    },
  },
};
</script>
