<template>
  <div class="pa-2">
    <v-card-text class="pt-4">
      <v-row>
        <v-form ref="createTargetOffersCode" class="w-100">
          <v-col cols="12">
            <v-text-field
              :rules="[rules.required]"
              required
              v-model="name"
              label="Name"
              @input="stringToSlug(name)"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :rules="[rules.required]"
              required
              v-model="slug"
              label="Slug"
              disabled
            ></v-text-field>
          </v-col>
        </v-form>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :loading="loading" color="blue darken-1" text @click="closeForm">
        Close
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        type="button"
        @click="onSave"
        :loading="loading"
      >
        Save
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { requestStatus } from "@/services/services";
import { createTargetOfffers } from "@/store/modules/offers/api";
import _ from "lodash";
// var slugify = require("slugify");
import slugify from "slugify";
export default {
  props: ["closeDialog"],
  data() {
    return {
      name: "",
      slug: "",
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required",
      },
    };
  },
  methods: {
    stringToSlug(value) {
      this.slug = slugify(value, {
        remove: /[^\w ]/g,
        lower: true,
      });
    },
    onSave() {
      if (this.$refs.createTargetOffersCode.validate()) {
        this.loading = true;
        let data = {
          name: this.name,
          slug: this.slug,
        };
        createTargetOfffers(data)
          .then((response) => {
            if (response.status === requestStatus.success) {
              this.$toaster.success("Country created");
              this.clearForm();
            } else {
              this.$toaster.error(
                response.messages || "Oops, something went wrong."
              );
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    clearForm() {
      this.name = "";
      this.slug = "";
      this.$refs.createTargetOffersCode.resetValidation();
    },
    closeForm() {
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss">
.change-server-wrp {
  .v-select.v-select--chips .v-select__selections {
    min-height: 32px !important;
  }
}
</style>
